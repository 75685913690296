<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="350"
    >
      <v-card
        tile
        class="mx-auto"
        max-width="350"
      >
        <v-container>
          <v-list>
            <v-subheader>
              ADICIONAR À PASTA
            </v-subheader>

            <v-divider />

            <v-list-item-group
              v-model="model"
              multiple
            >
              <template v-for="(item, i) in items">
                <v-divider
                  v-if="i > 0"
                  :key="`divider-${i}`"
                ></v-divider>

                <v-list-item
                  :key="`item-${i}`"
                  :value="item"
                  active-class="deep-purple--text text--accent-4"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title v-text="item" />
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox
                        :input-value="active"
                        color="deep-purple accent-4"
                      ></v-checkbox>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>

          <v-expand-transition>
            <div v-if="newVideoList">
              <v-text-field
                dense
                outlined
                label="Nome"
              />
            </div>
          </v-expand-transition>

          <v-divider />
        </v-container>

        <v-card-actions>
          <v-container class="pt-0">
            <v-btn text>
              ok
            </v-btn>

            <v-btn
              text
              @click="newVideoList = true"
            >
              criar pasta de vídeos
              <v-icon right>
                {{ mdiPlus }}
              </v-icon>
            </v-btn>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiPlus } from '@mdi/js'

export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    },
    dialogOn: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      mdiPlus,

      dialog: this.dialogOn,
      model: [],
      newVideoList: false
    }
  },
  watch: {
    dialog (val) {
      if (!val) this.$emit("dialogClose", val)
    },
    dialogOn (val) {
      if (val) this.dialog = true
    }
  }
}
</script>