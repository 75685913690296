import { render, staticRenderFns } from "./RoundedPlayer.vue?vue&type=template&id=4bc7bf05&scoped=true&"
import script from "./RoundedPlayer.vue?vue&type=script&lang=js&"
export * from "./RoundedPlayer.vue?vue&type=script&lang=js&"
import style0 from "./RoundedPlayer.vue?vue&type=style&index=0&id=4bc7bf05&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bc7bf05",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard})
