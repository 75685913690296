<template>
  <v-dialog
    v-model="youtubeDialog"
    max-width="600"
    :max-height="`${ $vuetify.breakpoint.xl ? 800 : 600 }`"
    @click:outside="$emit('close')"
  >
    <v-card>
      <v-container>
        <v-row
          align="center"
          justify="center"
          class="px-4"
        >
          <v-col
            cols="12"
            class="px-5 pb-0"
          >
            <v-text-field
              dense
              outlined
              clearable
              label="youtube link"
              :rules="[v => isYoutubeUrl(v) || 'Esse não é um link no formato correto']"
              v-model="youtubeLink"
            />
          </v-col>
        </v-row>

        <v-divider />

        <Scrollbar style="height: 400px;">
          <v-form ref="form">
            <v-container class="pb-0">
              <v-row justify="center">
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <v-progress-linear
                    :active="loading"
                    indeterminate
                    color="primary"
                    absolute
                    top
                  />

                  <v-container class="pb-0">
                    <v-row>
                      <v-col
                        cols="5"
                        class="py-0"
                      >
                        <v-hover v-slot:default="{ hover }">
                          <v-container class="px-0 py-0">
                            <v-card
                              outlined
                              rounded="lg"
                            >
                              <v-img
                                :src="thumbnailObj.mediaSrc"
                                aspect-ratio="1.9"
                              >
                                <template v-slot:placeholder>
                                  <v-skeleton-loader
                                    class="mx-auto"
                                    height="100%"
                                    width="100%"
                                    type="image"
                                  />
                                </template>

                                <v-fade-transition>
                                  <div
                                    v-if="hover"
                                    class="d-flex purple darken-4 v-card--reveal display-3 white--text"
                                    style="height: 100%; cursor: pointer;"
                                  >
                                    <v-icon 
                                      x-large
                                      color="white"
                                    >
                                      {{ mdiImage }}
                                    </v-icon>
                                  </div>
                                </v-fade-transition>
                              </v-img>
                            </v-card>
                          </v-container>
                        </v-hover>
                      </v-col>

                      <v-col
                        cols="7"
                        class="py-0"
                      >
                        <v-text-field
                          dense
                          outlined
                          label="Título"
                          readonly
                          v-model="title"
                        />

                        <v-text-field
                          dense
                          outlined
                          label="Autor"
                          readonly
                          v-model="author"
                        />
                      </v-col>
                    </v-row>

                    <v-row justify="center">
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-combobox
                          dense 
                          multiple
                          outlined
                          clearable
                          small-chips
                          validate-on-blur
                          readonly
                          label="Tags"
                          v-model="tags"
                        >
                          <template v-slot:selection="{ item, select, selected }">
                            <v-chip
                              label
                              outlined
                              small
                              color="secondary"
                              :key="JSON.stringify(item)"
                              :input-value="selected"
                              @click="select"
                            >
                              {{ item }}
                            </v-chip>
                          </template>
                        </v-combobox>
                      </v-col>
                    </v-row>

                    <v-row justify="center">
                      <v-col
                        cols="12"
                        class="py-0"
                      >
                        <v-textarea
                          rows="3"
                          outlined
                          auto-grow
                          label="Descrição"
                          readonly
                          v-model="description"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>

              <v-row
                justify="center"
                class="px-6"
              >
                <v-col
                  cols="12"
                  class="pt-0"
                >
                  <PrivacySettings @settings="setPrivacySettings" />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </Scrollbar>

        <v-card-actions class="py-0">
          <v-container class="py-0">

            <v-divider class="mb-2" />

            <v-row align="center">
              <v-btn
                plain
                :color="btnColor"
                :loading="loading"
                @click="submit"
              >
                enviar
              </v-btn>

              <v-spacer />

              <v-btn
                plain
                color="danger"
                :disabled="loading"
                @click="clear"
              >
                limpar
              </v-btn>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { Scrollbar } from '@components/App'
import { PrivacySettings, MediaURL } from '@components'
import { validateYoutubeUrl } from '@utils'
import API from '@api'

import { mdiClose, mdiImage } from '@mdi/js'

export default {

  components: {
    Scrollbar,
    PrivacySettings
  },

  props: {
    youtubeDialog: {
      type: Boolean,
      default: false
    },
    redirect: {
      type: Boolean,
      default: true
    }
  },

  created() {
    
    this.getMediaUrl = MediaURL.getMediaUrl.bind(this);

  },

  data() {
    return {
      mdiClose,
      mdiImage,

      loading: false,
      youtubeLink: '',
      youtubeId: '',

      thumbnailObj: {
        mediaSrc: '',
        filename: '',
        type: ''
      },

      title: '',
      author: '',
      description: '',
      tags: [],
      url: '',

      isAnonymous: false,
      isUnlisted: false,
      isPublic: true
      
    }
  },

  computed: {

    ...mapGetters({
      user: 'user',
      profile: 'profile/id',
      name: 'profile/name',
      avatar: 'profile/avatar',
      bio: 'profile/bio'
    }),

    sender() {
      return {
        profile: this.profile,
        name: this.name,
        avatar: this.avatar,
        bio: this.bio
      }
    },

    disableSubmit() {
      return !this.isYoutubeUrl(this.youtubeLink)
    },

    btnColor() {
      return this.$vuetify.theme.dark
        ? 'white'
        : 'primary'
    },

  },

  watch: {

    async youtubeLink(youtubeUrl) {
      
      this.clear()

      if (this.isYoutubeUrl(youtubeUrl)) {

        this.loading = true

        // TO DO: gotta review this for youtube video swith https://youtu.be

        this.youtubeId = youtubeUrl.split('watch?v=').pop().split('&').shift()

        const { exists } = await API().get('video/exists', {
          params: {
            youtubeId: this.youtubeId,
            isPublic: true
          }
        })

        if (exists) {

          this.clear()
          this.loading = false

          this.$emit('setSnack', 'youtubeExists')
          this.$emit('close')

        } else {

          try {

            const [{ snippet, status, contentDetails }] = await API().get('video/youtube', {
              params: {
                youtubeId: this.youtubeId
              }
            })
            const { contentRating } = contentDetails
            const { embeddable, privacyStatus } = status

            if (
              embeddable &&
              privacyStatus === 'public' &&
              contentRating.ytRating != "ytAgeRestricted"             
            ) {

              const { imgStr, imgExtension } = await API().get(`video/youtubeThumbnail/${this.user.id}`, {
                params: {
                  youtubeUrl,
                  youtubeId: this.youtubeId
                }
              })

              const base64Response = await fetch(`data:image/${imgExtension};base64,${imgStr}`)
              const blob = await base64Response.blob()

              this.thumbnailObj = {
                mediaSrc: URL.createObjectURL(blob),
                filename: `${this.youtubeId}.${blob.type.split('/').pop()}`,
                type: blob.type
              }

              this.title = snippet.title
              this.author = snippet.channelTitle
              this.description = snippet.description
              this.tags = snippet.tags
              this.duration = contentDetails.duration
              this.url = `https://www.youtube.com/watch?v=${this.youtubeId}`

            } else {

              this.clear()

              this.$emit('setSnack', 'youtubeOnly')
              this.$emit('close')

            }

          } catch (err) {

            this.clear()

            this.$emit('setSnack', 'youtubeNotFound')
            this.$emit('close')

          } finally {

            this.loading = false

          }

        }

      }
    }
  },

  methods: {

    async submit() {
      
      this.loading = true

      try {

        const [thumbnail] = await this.getMediaUrl([this.thumbnailObj], 'images/thumbnails')

        const video  = await API().post(`video/${this.user.id}`, {
          sender: this.sender,
          thumbnail,
          title: this.title,
          author: this.author,
          description: this.description,
          tags: this.tags,
          duration: this.duration,
          url: this.url,
          youtubeId: this.youtubeId,
          isYoutube: true,
          isLibraryContent: true,
          isAnonymous: this.isAnonymous,
          isPublic: this.isPublic,
          isUnlisted: this.isUnlisted
        })

        if (this.redirect) {

          this.$router.push({
            name: 'video.watch',
            query: {
              id: video.id
            }
          })

        } else {

          this.$emit('videoData', video)

          this.$emit('close')

        }
      
      } catch(err) {

        this.$emit('setSnack', 'errServer')

      } finally {

        this.loading = false

      }

    },

    clear() {

      this.thumbnailObj = {
        mediaSrc: '',
        filename: '',
        type: ''
      }
      this.youtubeLink = ''
      this.author = ''
      this.title = ''
      this.tags = []
      this.description = ''
      this.isAnonymous = false
      this.isUnlisted = false,
      this.isPublic = true

    },

    isYoutubeUrl(str) {
      return validateYoutubeUrl(str)
    },

    setPrivacySettings({ isAnonymous, isUnlisted, isPublic }) {

      this.isAnonymous = isAnonymous
      this.isUnlisted = isUnlisted
      this.isPublic = isPublic

    }

  }

}

</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  border-radius: 4px;
  justify-content: center;
  opacity: .7;
  position: absolute;
  width: 100%;
}
</style>