<template>
  <v-container>
    <v-card
      hover
      :outlined="$vuetify.theme.dark"
      :ripple="false"
      :to="`/video/watch?id=${id}`"
    >
      <v-img
        :src="thumbnail"
        max-height="250"
        aspect-ratio="1.7"
      >
        <template v-slot:placeholder>
          <v-skeleton-loader
            class="mx-auto"
            height="100%"
            width="100%"
            type="image"
          />
        </template>
      </v-img>
    </v-card>

    <v-list-item class="px-2">
      <v-list-item-content>
        <v-list-item-title 
          class="grey--text text--darken-3"
          v-text="author"
        />
        <span
          style="height: 64px;"
          :class="`text-h${$vuetify.breakpoint.xl ? 5 : 6} clamp-text font-weight-regular`"
          v-text="title"
        />
        
        <v-list-item-subtitle v-text="description" />
      </v-list-item-content>
    </v-list-item>

    <v-divider inset />

    <v-card-actions class="px-0 py-0">
      <v-list-item>
        <v-list-item-avatar>
          <v-img
            :src="sender.avatar"
            v-if="!isAnonymous && sender.avatar"
          />
          <v-btn
            v-else
            icon
            x-large
            color="grey darken-1"
          >
            <v-icon 
              dark
              x-large
            >
              {{ mdiAccountCircle }}
            </v-icon>
          </v-btn>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="name" />
        </v-list-item-content>

        <v-list-item-action>
          <v-list-item-action-text v-text="`${dateDistance}`" />
        </v-list-item-action>
      </v-list-item>
    </v-card-actions>
  </v-container>
</template>

<script>
import * as durationFns from 'duration-fns';
import formatDuration from 'date-fns/formatDuration'
import formatDistance from 'date-fns/formatDistance'
import { mdiAccountCircle } from '@mdi/js'

export default {
  name: 'VideoYouTube',

  props: {
    id: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    author: {
      type: String,
      required: true
    },
    duration: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    thumbnail: {
      type: String,
      required: true
    },
    sender: Object,
    preview: {
      type: Boolean,
      default: true
    },
    createdAt: {
      type: Boolean,
      required: true
    },
    isYoutube: {
      type: Boolean,
      default: false
    },
    isAnonymous: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      mdiAccountCircle
    }
  },

  computed: {
    name () {
      return this.isAnonymous
        ? 'Anonymous'
        : this.sender.name
    },

    dateDistance () {
      return formatDistance(
        new Date(this.createdAt),
        new Date(),
        { addSuffix: true }
      )
    },

    parsedDuration () {
      return formatDuration(durationFns.parse(this.duration))
    }
  }
}
</script>

<style scoped>
a { text-decoration: none; }
</style>