<template>
  <v-container
    class="my-4"
    @click="$emit('focus')"
  >
    <v-fade-transition hide-on-leave>
      <v-text-field
        v-if="!url || !thumbnail"
        dense
        outlined
        hide-details
        label="Youtube Link"
        v-model="url"
      />
    </v-fade-transition>
    <v-fade-transition hide-on-leave>
      <div v-if="url && thumbnail">
        <YouTubePlayer
          :url="url"
          :thumbnail="thumbnail"
        />
        <v-fade-transition hide-on-leave>
          <v-container
            v-if="meta.caption || (focusOn && editorOn)"
            class="py-0"
          >
            <v-row
              justify="center"
              align="center"
            >
              <v-col
                xl="12"
                lg="12"
                class="px-0 pb-0"
              >
                <v-fade-transition hide-on-leave>
                  <p
                    v-if="meta.caption && !editCaption"
                    v-text="meta.caption"
                    class="subtitle-1 grey--text text--darken-2 text-center clickable"
                    @click="editCaption = true"
                  />
                </v-fade-transition>
                <v-fade-transition hide-on-leave>
                  <v-text-field
                    v-if="!meta.caption || editCaption"
                    v-model="captionData"
                    dense
                    outlined
                    hide-details
                    label="Legenda"
                    @focus="$emit('setPartInput', true)"
                    @blur="$emit('setPartInput', false)"
                    @keydown="onKeyDown"
                  />
                </v-fade-transition>
              </v-col>
            </v-row>
          </v-container>
        </v-fade-transition>
      </div>
    </v-fade-transition>
  </v-container>
</template>

<script>
import YouTubePlayer from './YouTubePlayer'
import { mapGetters } from 'vuex'
import API from '@api'

export default {
  components: {
    YouTubePlayer
  },
  
  props: {
    partIdx: {
      type: Number,
      required: true
    },
    focusOn: {
      type: Boolean,
      required: true
    },
    editorOn: {
      type: Boolean,
      required: true
    },
    src: {
      type: String,
      required: true
    },
    meta: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  mounted() {
    if (this.src) this.url = this.src
    if (this.meta.thumbnail) this.thumbnail = this.meta.thumbnail
    if (this.meta.caption) this.captionData = this.meta.caption
    else this.editCaption = true
  },

  data() {
    return {
      editCaption: false,
      captionData: '',
      url: '',
      thumbnail: '',
      duration: ''
    }
  },

  watch: {
    async url(newVal) {
      if (newVal) {
        if (this.validateYoutubeUrl(newVal)) {
          const youtubeId = newVal.split('watch?v=').pop().split('&').shift()
          const params = {
            youtubeId
          }
          const { exists } = await API().get('video/exists', { params })
          if (exists) {
            this.toggleSnack('youtubeExists')
          } else {
            const data = await API().get('video/youtube', {params})
            const { status } = data[0]
            try {
              if (status.embeddable && status.privacyStatus === 'public') {
                this.thumbnail = `https://img.youtube.com/vi/${youtubeId}/0.jpg`
                this.$emit('setMeta', { caption: this.caption, thumbnail: this.thumbnail })
                this.url = `https://www.youtube-nocookie.com/watch?v=${youtubeId}`
                this.$emit('content', this.url)
              } else {
                this.toggleSnack('youtubeOnly')
              }
            } catch (e) {
              this.toggleSnack('youtubeNoVideo')
            }
          }
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      user: 'user'
    })
  },

  methods: {
    onKeyDown(e) {
      if (e.key === 'Enter') {
        this.editCaption = false
        this.$emit('setMeta', { caption: this.captionData, thumbnail: this.thumbnail })
        setTimeout(() => {
          this.$emit('setPartInput', false)
        }, 0)
      }
    },
    validateYoutubeUrl (link) {
      if (link) {
        const split1 = link.split('https://www.youtube.com')
        const split2 = link.split('https://youtube.com')
        const condition1 =  split1.length > 1 && split1[1].split('=')[0] === '/watch?v'
        const condition2 =  split2.length > 1 && split2[1].split('=')[0] === '/watch?v'
        return condition1 || condition2
      } else return false
    }
  }
}
</script>