<template>
  <v-card
    :id="`youtubeContainer-${videoId}`"
    :class="playerClass"
    elevation="0"
    :rounded="borderRadius"
    @click="doubleClick"
    v-ripple="false"
    v-click-outside="updateUrl"
  >
    <video
      :id="`youtubePlayer-${videoId}`"
      ref="youtubePlayer"
      fluid
      controls
      :poster="thumbnail"
      :data-setup="dataSetup"
      class="video-js vjs-big-play-centered"
    ></video>
  </v-card>
</template>

<script>
import videojs from 'video.js/dist/video';

import '@hisolver/videojs-youtube/dist/Youtube';

import 'video.js/dist/video-js.min.css';
import '@assets/styles/video-js.css';

require('@hisolver/videojs-theater-mode/dist/videojs.theaterMode');
require('@hisolver/videojs-theater-mode/dist/videojs.theaterMode.css');

import { mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    options: {
      type: Object,
      default () {
        return {
          controls: true,
          autoplay: false,
          responsive: true,
          playbackRates: [0.5, 1, 1.5, 2]
        }
      }
    },
    videoId: {
      type: Number,
      default: 0
    },
    borderRadius: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      required: true
    },
    thumbnail: {
      type: String,
      default: ''
    },
    isPreview: {
      type: Boolean,
      default: false
    },
    syncTheaterMode: {
      type: Boolean,
      default: true
    },
    mediaViewer: {
      type: Boolean,
      default: false
    },
    syncMediaViewer: {
      type: Boolean,
      default: false
    },
    setHeight: {
      type: Boolean,
      default: false
    }
  },

  mounted () {
    this.player = videojs(
      this.$refs.youtubePlayer,
      this.options
    )

    this.player.on('error', () => {
      this.$emit('youtubeError', 'errYouTube')
    })

    if (!this.isPreview) {
      
      this.player.theaterMode({
        elementToToggle: `youtubeContainer-${this.videoId}`,
        className: 'video-js-theater'
      })
      
      this.player.on('theaterMode', (elm, { theaterModeIsOn }) => {

        this.theaterModeOn = theaterModeIsOn

        this.$emit('theaterMode', !theaterModeIsOn)

        if (this.syncTheaterMode) {

          this.setNavOn(!theaterModeIsOn)
          this.setNavTempOn(theaterModeIsOn)
        
        } else {
        
          this.player.pause()
        
        }
  
      })

    }

    const resizeObserver = new ResizeObserver(([{ target }]) => {
      this.$emit('playerHeight', {
        height: target.clientHeight,
        theaterModeOn: this.theaterModeOn
      })
    })
    resizeObserver.observe(document.getElementById(`youtubePlayer-${this.videoId}`))
  },

  beforeDestroy() {
    if (this.player)
      this.player.dispose()

    this.setNavOn(true)
    this.setNavTempOn(false)
    if (this.navTemp) this.toggleNavTemp()
  },

  data () {
    return {
      player: null,
      theaterModeOn: false,

      loaded: false,
      clicks: 0
    }
  },

  watch: {
    syncMediaViewer() {
      this.player.pause()
    },

    theaterModeOn (theater) {
      this.$emit('theaterModeToggle', theater)
    }
  },

  computed: {
    ...mapGetters({
      navTempOn: 'application/navTempOn',
      navTemp: 'application/navTemp',
      navOn: 'application/navOn'
    }),

    dataSetup() {
      const dataSetup = {
        "techOrder": ["youtube"],
        "sources": [{
          "type": "video/youtube",
          "src": this.url,
          "youtube": {
            "iv_load_policy": 3,
            "enablePrivacyEnhancedMode": "true"
          }
        }]
      }
      return JSON.stringify(dataSetup)
    },

    playerClass() {
      const mediaViewerClass = 'video-js-media-viewer';
      const baseClass = 'video-js-responsive' // TODO: figure out why we had the class 'vjs-hd' here
      const paddingClass = 'vjs-hd'
      const breakPoint = this.$vuetify.breakpoint.xl ? 'xl' : 'lg'
      const theaterClass = `video-js-theater video-js-theater-${breakPoint}`
      const heightClass = 'video-js-height'

      return this.theaterModeOn
        ? theaterClass
        : this.mediaViewer
        ? `${baseClass} ${mediaViewerClass}`
        : this.setHeight
        ? `${baseClass} ${heightClass}`
        : `${baseClass} ${paddingClass}`
    }
  },

  methods: {
    ...mapMutations({
      setNavOn: 'application/navOn',
      toggleNavTemp: 'application/navTemp',
      setNavTempOn: 'application/navTempOn'
    }),

    updateUrl() {
      this.loaded = false
    },

    doubleClick() {

      this.clicks++

      if (!this.loaded && this.clicks < 20) {

        setTimeout(() => {

          if (this.player.bigPlayButton.el_)
            this.player.bigPlayButton.el_.click()
          
        }, 100)

      } else {

        this.loaded = true
        this.clicks = 0
      
      }      
      
    }
  }
}
</script>

<style lang="scss" scoped>
.video-js-height {
  height: 45vh !important;
}
.video-js-media-viewer {
  height: 100vh;
}

.video-js-responsive.vjs-hd {
  padding-top: 56.25%;
}

.video-js-responsive.vjs-sd {
  padding-top: 75%;
}

.video-js-responsive {
  width: 100%;
  position: relative;
}

.video-js-responsive .video-js {
  height: 100% !important; 
  width: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}

.video-js-theater { 
  width: 100%;
  position: relative;
}

.video-js-theater-xl .video-js { 
  width: 100%;
  height: 60em;
  position: absolute;
  top: 0;
  left: 0;
}

.video-js-theater-lg .video-js { 
  width: 100%;
  height: 40em;
  position: absolute;
  top: 0;
  left: 0;
}
</style>