<template>
  <v-container
    class="mt-2"
    @click="$emit('focus')"
  >
    <v-fade-transition hide-on-leave>
      <div v-if="url">        
        <VideoPlayer
          :videoId="url.split('/').slice(-1)[0]"
          :url="url"
          :tags="tags"
          :title="title"
          :author="author"
          :sender="sender"
          :thumbnail="thumbnail"
        />
        <v-expand-transition hide-on-leave>
          <v-container
            v-if="meta.caption || (focusOn && editorOn)"
            class="py-0"
          >
            <v-row
              justify="center"
              align="center"
            >
              <v-col
                xl="12"
                lg="12"
                class="px-0 pb-0"
              >
                <v-fade-transition hide-on-leave>
                  <p
                    v-if="meta.caption && !editCaption"
                    v-text="meta.caption"
                    class="subtitle-1 grey--text text--darken-2 text-center clickable"
                    @click="editCaption = true"
                  />
                </v-fade-transition>
                <v-fade-transition hide-on-leave>
                  <v-text-field
                    v-if="!meta.caption || editCaption"
                    v-model="captionData"
                    dense
                    outlined
                    hide-details
                    label="Legenda"
                    @focus="$emit('setPartInput', true)"
                    @blur="$emit('setPartInput', false)"
                    @keydown="onKeyDown"
                  />
                </v-fade-transition>
              </v-col>
            </v-row>
          </v-container>
        </v-expand-transition>
      </div>
    </v-fade-transition>
  </v-container>
</template>

<script>
import VideoPlayer from './Player'
import { mapGetters } from 'vuex'
import * as durationFns from 'duration-fns'
import API from '@api'

export default {
  components: {
    VideoPlayer
  },
  
  props: {
    partStr: {
      type: String,
      required: true
    },
    partIdx: {
      type: Number,
      required: true
    },
    focusOn: {
      type: Boolean,
      required: true
    },
    editorOn: {
      type: Boolean,
      required: true
    },
    src: {
      type: String,
      required: true
    },
    thumbnailSrc: {
      type: String,
      default: ''
    },
    meta: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  async mounted() {
    
    if (this.meta.caption) {
      
      this.captionData = this.meta.caption
    
    }

    if (this.src) {

      this.setupVideo()
    
    }

  },

  data() {
    return {
      editCaption: false,
      captionData: '',
      url: '',
      duration: '',
      thumbnail: ''
    }
  },

  computed: {
    ...mapGetters({
      user: 'user'
    })
  },

  watch: {

    src() {

      this.setupVideo()

    }

  },

  methods: {

    async setupVideo() {

      const videoFile = await fetch(this.src).then(r => r.blob())
      
      let formData = new FormData()
      
      formData.append('video', videoFile)
      
      const config = {
        header: {
          'Content-Type': 'multipart/form-data',
          'Content-Length': videoFile.size
        }
      }
      
      const video = document.createElement('video')
      
      video.preload = 'metadata'
      
      video.onloadedmetadata = () => {
        
        const normalizedDuration = durationFns.normalize({
          seconds: Math.ceil(video.duration)
        })

        this.duration = durationFns.toString(normalizedDuration)
        video.remove()
      
      }

      API().post(`video/thumbnail/${this.user.id}`,
        formData,
        config
      ).then(({ thumbnail }) => {

        if (!this.thumbnail) {

          this.thumbnail = `data:image/jpeg;base64,${thumbnail}`

          this.$emit('setThumbnail', {
            thumbnail: this.thumbnail,
            index: this.partIdx
          })

        } else {
          
          this.thumbnail = this.thumbnailSrc

        }
        
        this.url = ''

        setTimeout(() => {

          this.url = this.src

        })
      
      })

    },

    onKeyDown(e) {
      if (e.key === 'Enter') {
        this.editCaption = false
        this.$emit('setMeta', { caption: this.captionData })
        setTimeout(() => {
          this.$emit('setPartInput', false)
        }, 0)
      }
    }
  }
}
</script>