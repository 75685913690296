<template>
  <v-list>
    <v-subheader v-text="'Comentários e Sugestões'" />
    <v-list-item-group
      v-model="selected"
      multiple
    >
      <v-list-item
        v-for="(item, i) in items.filter((item, idx) => idx < limit)"
        :key="item.title"
      >
        <template v-slot:default="{ active }">
          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
            <v-list-item-subtitle
              class="primary--text"
              v-text="item.headline"
            />
            <v-list-item-subtitle v-text="item.subtitle" />
          </v-list-item-content>
        </template>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  props: ['items', 'limit'],
  data () {
    return {
      selected: []
    }
  }
}
</script>